import React, { useContext } from "react";
import { HomeContext } from "../HomeContext";
import StarsIcon from "@mui/icons-material/Stars";
const sample = ["Welcome to Vimalagiri International School, Muvattupuzha"];

const Marquee = ({ items = sample, speed = 18 }) => {
  const { homeData, loading, error } = useContext(HomeContext);
  const blogTitles = homeData?.latest_blog?.map((blog) => blog.blog_title);

  if (loading) return null;
  if (error) return null;
  return (
    <div
      style={{
        marginTop: "1rem",
        // backgroundColor: "#97022c",
        fontSize: "28px",
        overflow: "hidden", // Hide any overflow content
        whiteSpace: "nowrap",
        padding: "0 0",
        position: "relative", // Position absolute to ensure it's on top of other content
        top: 0, // Ensure it's at the top of the page
        left: 0,
        width: "99vw", // Full viewport width
        color: "#97022c",
        // fontWeight:'bold'
      }}
    >
      <div
        style={{
          display: "flex",
          animation: `marquee ${speed}s linear infinite`,
          willChange: "transform",
        }}
      >
        {blogTitles.map((item, index) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <StarsIcon style={{ fontSize: "32px", textAlign: "end" }} />
            <p
              key={index}
              style={{
                marginRight: "200px",
                textAlign: "top",
                fontSize: "24px",
              }}
            >
              {item}
            </p>
          </div>
        ))}
      </div>
      <style>
        {`
          @keyframes marquee {
            from {
              transform: translateX(100vw); /* Start fully outside the right edge */
            }
            to {
              transform: translateX(-100vw); /* Move fully outside the left edge */
            }
          }
        `}
      </style>
    </div>
  );
};

export default Marquee;
