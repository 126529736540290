import { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/common/header/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./components/about/About";
import Team from "./components/team/Team";
import Blog from "./components/blog/Blog";
import Contact from "./components/contact/Contact";
import Footer from "./components/common/footer/Footer";
import Home from "./components/home/Home";
import { ContactProvider } from "./components/contact/ContactContext";
import { BlogProvider } from "./components/blog/BlogContext";
import BlogCard from "./components/blog/BlogCard";
import BlogLayout from "./components/blog/Indi_blog";
import VlogList from "./components/vlog/Vlog";
import Gallery from "./components/team/Academic";
import Galleryy from "./components/team/Galleryy";
import A from "./components/team/A";
import B from "./components/team/B";
import C from "./components/Facility/C";
import Marquee from "./components/home/hero/Marquee";
import { HomeProvider } from "./components/home/HomeContext";
// import TestComponent from "./components/Test";

function App() {

  const [opacity, setOpacity] = useState(1); // Default fully visible

  useEffect(() => {
    const updateOpacity = () => {
      const startDate = new Date(2025, 1, 20); // February 20, 2025
      const today = new Date();
      const daysPassed = Math.floor((today - startDate) / (1000 * 60 * 60 * 24));
      let newOpacity = 1 - daysPassed * 0.1; // Reduce by 10% per day
  
      setOpacity(newOpacity);
    };
  
    updateOpacity(); // Run on mount
  
    // Run the effect every 24 hours at midnight
    const interval = setInterval(() => {
      updateOpacity();
    }, 1000 * 60 * 60 * 24); // Every 24 hours
  
    return () => clearInterval(interval); // Cleanup when unmounting
  }, []);
  

  const ContactWrapper = () => (
    <ContactProvider>
      <Contact />
    </ContactProvider>
  );

  const FooterWrapper = () => (
    <BlogProvider>
      <ContactProvider>
        <Footer />
      </ContactProvider>
    </BlogProvider>
  );

  const BlogWrapper = () => (
    <BlogProvider>
      <BlogLayout />
    </BlogProvider>
  );

  return (
    <>
      <div className="app-container" style={{ opacity }}>
        {opacity === 0 && <div className="white-screen"></div>}
        <Router basename="/">
          {/* basename="/school" */}
          <Header />
          <HomeProvider>
            <Marquee />
          </HomeProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            {/* <Route path="/courses" element={<CourseHome />} /> */}
            <Route path="/gallery" element={<Team />} />
            {/* <Route path="/pricing" element={<Pricing />} /> */}
            <Route path="/news" element={<Blog />} />
            <Route path="/vlog" element={<VlogList />} />
            <Route path="/facility" element={<VlogList />} />

            <Route path="/gallery/type1" element={<A />} />
            <Route path="/gallery/type2" element={<B />} />
            <Route path="/gallery/type3" element={<C />} />

            <Route path="/contact" element={<ContactWrapper />} />
            {/* <Route path="/test" element={<TestComponent />} /> */}

            <Route path="/blog/:id" element={<BlogWrapper />} />
          </Routes>
          <FooterWrapper />
        </Router>
      </div>

    </>
  );
}

export default App;

